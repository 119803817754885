import styled from "styled-components"

export const Styled = styled.div`
  .ant-picker {
    height: 37px;
  }
  .ant-picker-focused {
    box-shadow: unset;
  }
`
